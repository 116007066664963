<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition">
          <div class="form-label-title">
            清扫保洁标段情况
          </div>
          <div class="form-label-base">
            面积与单价
          </div>
          <el-form-item label="清扫保洁面积（平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="平均单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">特级路</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">一级路</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">二级路</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">三级路</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">四级路</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">城中村</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">市政道路扣除广场步行街剩余面积</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">绿地面积</div>
          <el-form-item label="面积（万平米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.平方米）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-base">
            清扫保洁车辆管理
          </div>
          <div class="form-label-test">车辆统计</div>
          <el-form-item label="车辆总数:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="行驶证上传数量:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="清洗车:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="扫路车:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="垃圾收集车:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="管理用车:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <div class="form-label-base">
            清扫保洁人员管理
          </div>
          <div class="form-label-test">人员统计</div>
          <el-form-item label="人员总数:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="身份证上传数量:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="项目经理:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="安全生产管理人员:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="一线保洁人员:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="车队长:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="片区负责人:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="作业司机:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          
          <!-- 垃圾清运标段情况 -->
          <div class="form-label-title">
            垃圾清运标段情况
          </div>
          <div class="form-label-base">
            作业量与单价
          </div>
          <el-form-item label="总吨数" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="平均单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">垃圾中转站</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">公厕</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">吨数（含前端运输）</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">吨数（不含前端运输）</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">运输距离（含前端）</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-test">运输距离（不含前端）</div>
          <el-form-item label="数量" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="单价（元/年.吨）" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="说明" prop="areaUunitPrice.cargoArrivalNumber">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <div class="form-label-base">
            垃圾清运车辆管理
          </div>
          <div class="form-label-test">车辆统计</div>
          <el-form-item label="人数总数:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="身份证上传数量:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="项目经理:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="车队长:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="安全生产管理员:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="垃圾收集工:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="公厕保洁人员:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>
          <el-form-item label="作业司机:">
            <span>{{ form.areaUunitPrice.cargoArrivalNumber }}</span>
          </el-form-item>

          <!-- 审核结果 -->
          <div class="form-label-base">
            审核结果
          </div>
          <el-form-item label="审核结果:" style="width: 100%">
            <el-radio v-model="form.areaUunitPrice.radio" label="通过">通过</el-radio>
            <el-radio v-model="form.areaUunitPrice.radio" label="不通过">不通过</el-radio>
          </el-form-item>
          <el-form-item label="审核意见" style="width: 100%">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
          <el-form-item label="审核人员" style="width: 100%">
            <el-input v-model="form.areaUunitPrice.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="submitForm()">整体提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        submitLoading: false,
        form: {
          areaUunitPrice: {
            cargoArrivalNumber: '测试',
            radio: null,
          },
          
        },
        rules: {
          "areaUunitPrice.cargoArrivalNumber": {required: true, trigger: 'blur'},
        },
        
      }
    },
    methods: {
        submitForm() {}
    },

    async mounted() {
        
    },
  }
</script>

<style lang='sass' scoped>
.form-label-title
    color: #1ABC9C
    font-size: 18px
    line-height: 46px

.form-label-test
    font-size: 14px
    color: rgba(126,129,135,1)
    line-height: 46px

/deep/.form-label-base
    margin: 20px 0 10px
</style>
